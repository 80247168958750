import { default as emailsPgI4CB1a6jMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/emails.vue?macro=true";
import { default as integrationsZFt2cIiyVsMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/integrations.vue?macro=true";
import { default as overview3DnzQqv6AiMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/overview.vue?macro=true";
import { default as index8A8IncxWinMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsJUssmZg6djMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexSUPFvV1sOQMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/index.vue?macro=true";
import { default as indexjtxGMVO63uMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/[id]/index.vue?macro=true";
import { default as addyRzGDVzwlRMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/add.vue?macro=true";
import { default as index5dS7btbkaAMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/index.vue?macro=true";
import { default as adminAfCNtVOSYxMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin.vue?macro=true";
import { default as accountmfWC3U1AWDMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/account.vue?macro=true";
import { default as entourageSkhErdXL76Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsELeaMy11vrMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthOEZfRtXUqlMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoVBi1eGrtWYMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupationIWvVmuxpdBMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdM6jFqwKXVKMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as print3TQsl9OkvaMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as index2pDkPHn32dMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/index.vue?macro=true";
import { default as editO3TOhCtIiaMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editwAmda6r4odMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editIYKe1lz4OfMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addR7PQpXL2FiMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as editC2NecltUz4Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addAexGygEgpsMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editmSJZf8vqWYMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addHUbycPTzZpMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editgZ6QrAhx2gMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indextjhg61ZJ9TMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/index.vue?macro=true";
import { default as add9cajF8qSB0Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/add.vue?macro=true";
import { default as historyjwMJ268MQfMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/history.vue?macro=true";
import { default as printXwOrGIPpxTMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewFlYjWmold8Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/overview.vue?macro=true";
import { default as partnerslVc4ksHzTUMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/partners.vue?macro=true";
import { default as statsWqqNyHXZC3Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/stats.vue?macro=true";
import { default as index9n2lkGJkr1Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/structure/index.vue?macro=true";
import { default as subjectskOltDyzibEMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/structure/subjects.vue?macro=true";
import { default as usersUABiDTWVwnMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/users.vue?macro=true";
import { default as app8H3ahz3YF8Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app.vue?macro=true";
import { default as adminvfDfVFzPhPMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/admin.vue?macro=true";
import { default as loginW4Urr9TA00Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/login.vue?macro=true";
import { default as logoutqDlKvPtL4vMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/logout.vue?macro=true";
import { default as proconnectwQ13RvUJNGMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/proconnect.vue?macro=true";
import { default as validate_45token4N04QpDtGxMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/validate-token.vue?macro=true";
import { default as manager0T2amI8nt6Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu/manager.vue?macro=true";
import { default as usernY1Yla0qDEMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu/user.vue?macro=true";
import { default as cgu3AnNoxLYsUMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteBok1l6X1TIMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accounthMX5LT6SsXMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/error/disabled-account.vue?macro=true";
import { default as unknown_45userOMlM45qCx9Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/error/unknown-user.vue?macro=true";
import { default as indexLkiqnr2bZaMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/index.vue?macro=true";
import { default as mentions_45legalesLeXyxNCOy5Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitex10ePA7p7MMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/politique-confidentialite.vue?macro=true";
import { default as registerxgul2fLe7XMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/register.vue?macro=true";
import { default as statistiquesLaPRUstgsYMeta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/statistiques.vue?macro=true";
import { default as storiesUF6gwLcXi5Meta } from "/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminAfCNtVOSYxMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/emails.vue").then(m => m.default || m)
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/integrations.vue").then(m => m.default || m)
  },
  {
    name: overview3DnzQqv6AiMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overview3DnzQqv6AiMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/[id]/subjects.vue").then(m => m.default || m)
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/structures/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/add.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: app8H3ahz3YF8Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/account.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/entourage.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/external-organisations.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/health.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/info.vue").then(m => m.default || m)
  },
  {
    name: occupationIWvVmuxpdBMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupationIWvVmuxpdBMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/occupation.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/edit/tax-household.vue").then(m => m.default || m)
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: print3TQsl9OkvaMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: index2pDkPHn32dMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: index2pDkPHn32dMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/beneficiaries/index.vue").then(m => m.default || m)
  },
  {
    name: editO3TOhCtIiaMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editO3TOhCtIiaMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/budget/edit.vue").then(m => m.default || m)
  },
  {
    name: editwAmda6r4odMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editwAmda6r4odMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/expenses/[eid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/followups/[fid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/followups/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/[subject]/add.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/help-requests/housing/add.vue").then(m => m.default || m)
  },
  {
    name: editgZ6QrAhx2gMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editgZ6QrAhx2gMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/income/[iid]/edit.vue").then(m => m.default || m)
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/files/add.vue").then(m => m.default || m)
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/history.vue").then(m => m.default || m)
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printXwOrGIPpxTMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/historyprint/[id]/print.vue").then(m => m.default || m)
  },
  {
    name: overviewFlYjWmold8Meta?.name ?? "app-overview",
    path: "overview",
    meta: overviewFlYjWmold8Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/overview.vue").then(m => m.default || m)
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/partners.vue").then(m => m.default || m)
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/stats.vue").then(m => m.default || m)
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/structure/index.vue").then(m => m.default || m)
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/structure/subjects.vue").then(m => m.default || m)
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/app/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminvfDfVFzPhPMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/admin.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginW4Urr9TA00Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutqDlKvPtL4vMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-proconnect",
    path: "/auth/proconnect",
    meta: proconnectwQ13RvUJNGMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/proconnect.vue").then(m => m.default || m)
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45token4N04QpDtGxMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/auth/validate-token.vue").then(m => m.default || m)
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cgu3AnNoxLYsUMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu.vue").then(m => m.default || m),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu/manager.vue").then(m => m.default || m)
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/cgu/user.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteBok1l6X1TIMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/declaration-accessibilite.vue").then(m => m.default || m)
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accounthMX5LT6SsXMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/error/disabled-account.vue").then(m => m.default || m)
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userOMlM45qCx9Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/error/unknown-user.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexLkiqnr2bZaMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesLeXyxNCOy5Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitex10ePA7p7MMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/politique-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerxgul2fLe7XMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesLaPRUstgsYMeta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/statistiques.vue").then(m => m.default || m)
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesUF6gwLcXi5Meta || {},
    component: () => import("/build/2bed7c71-8663-46c2-bf6d-fb3fc1bd3f68/pages/stories.vue").then(m => m.default || m)
  }
]